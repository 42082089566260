@font-face {
	font-family: SpartanMB;
	src: url('./assets/font/SpartanMB-ExtraBold.otf');
	font-weight: 700;
}
@font-face {
	font-family: SpartanMB;
	src: url('./assets/font/SpartanMB-SemiBold.otf');
	font-weight: 500;
}
@font-face {
	font-family: SpartanMB;
	src: url('./assets/font/SpartanMB-Regular.otf');
	font-weight: 400;
}

/* Colors */
.color-purple {
  color: #5D28D1;
}

.color-pink {
  color: #D1288B;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SpartanMB';
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #F7E8EF !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}


/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #F7E8EF;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #D1288B !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
    color: #5D28D1 !important;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/********************* Banner CSS *********************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  /* background: black; */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-maintab {
  width: 100%;
  height: 100%;
  min-width: 400px;  
  min-height: 300px;
  padding: 80px 50px 20px 50px;
  background-image: url('./assets/img/banner-left.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.header-img {
  transform: scale(1.06); /* Adjust the scale as needed */
  margin: -10px; /* Optional: adds negative margin to avoid empty space */
}

.folder-icon-img {
  width: 136px;
  height: auto;
}

  @media (max-width: 768px) {
    .folder-icon-img {
      width: 100px;
    }
  }

  @media (max-width: 576px) {
    .folder-icon-img {
      width: 80px;
    }
  }

/************ About me CSS *************/
.aboutmebox {
  max-width: 355px; /* Ensures the box scales smaller up to this max width */
  width: 100%; /* Makes the box responsive */
}

@media (max-width: 1200px) {
  .aboutmebox {
    align-self: center;
  }
}

@media (max-width: 768px) {
  .aboutmebox {
    max-width: 270px;
  }
}

@media (max-width: 576px) {
  .aboutmebox {
    max-width: 150px;
  }
}

.aboutmebubble {
  background-image: url('./assets/img/aboutme-bubble.png');
  background-size: contain; /* Ensures the image covers the div */
  background-repeat: no-repeat;
  max-width: 722px; /* Set a max width if needed */
  width: 100%;
  height: 150%;
}

  @media (max-width: 1200px) {
    .aboutmebubble {
      padding: 0;
      background-image: none;
    }
  }

.aboutmebubble p {
  margin: 0; /* Removes extra margin */
  padding-right: 15px; /* Adds right padding for text spacing */
}

/*************** My Works CSS **************/
.myworks-box {
  max-width: 355px;
}

/* Style for the active tab */
.nav-pills .nav-link.active {
  background-color: #FCE1F2 !important; /* Replace with your desired color */
  color: #fff; /* Text color when active */
}

.nav-pills .nav-link {
  color: #6a1b9a; /* Default text color */
  border-radius: 30px;
}

.nav-pills .nav-link:hover {
  background-color: #6a1b9a; /* Change the hover color */
  color: #fff;
}

/* If you want to change the color of the icons in the active tab */
.nav-pills .nav-link.active img {
  filter: brightness(0) saturate(100%) invert(25%) sepia(100%) saturate(550%) hue-rotate(180deg) brightness(110%) contrast(100%); /* Example filter for icon color change */
}


/** Modal styles **/
.modal-fullscreen {
  z-index: 1050 !important;
}

.modal-body img {
  width: 100%;
  max-width: 90vw;
  height: auto;
  max-height: 80vh;
  margin: 5px auto;
  display: block;
  object-fit: contain;
  border-radius: 8px;
}

/** Masonry layout styles **/
.projects-masonry {
  display: flex;
  width: 100%;
  margin-left: -30px; /* Increased gap compensation */
}

.projects-masonry_column {
  padding-left: 30px; /* Increased column gap */
  background-clip: padding-box;
  width: 33.33% !important; /* Force column width */
}

/* Project card styling */
.project-card {
  position: relative;
}

.project-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.project-card:hover .overlay {
  opacity: 1;
}

.project-card .overlay-text {
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}


/* Responsive adjustments */
@media (max-width: 991px) {
  .projects-masonry_column {
    width: 50% !important;
  }
}

@media (max-width: 480px) {
  .projects-masonry_column {
    width: 100% !important;
  }
  
  .projects-masonry {
    margin-left: -20px;
  }
  
  .projects-masonry_column {
    padding-left: 20px;
  }
}

/**************** Footer CSS ****************/
.footer {
  background: #F7B7DA;
}

.social-icon {
  max-width: 100px;
}

.social-icon img {
  transition: filter 0.3s ease;
}

.social-icon:hover img {
  filter: brightness(0.7);
}